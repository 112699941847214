import { render, staticRenderFns } from "./ExploreGifts.vue?vue&type=template&id=168d5aec&scoped=true&"
import script from "./ExploreGifts.vue?vue&type=script&lang=js&"
export * from "./ExploreGifts.vue?vue&type=script&lang=js&"
import style0 from "./ExploreGifts.vue?vue&type=style&index=0&id=168d5aec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168d5aec",
  null
  
)

export default component.exports