<template>
  <div class="explore">
    <div class="explore__title"><h1>browse 59 gift ideas in tech</h1></div>
    <div class="explore__images">
      <div class="explore__images-item" v-for="item in 24" :key="item">
        <router-link :to="{ name: 'GiftDetails' }">
          <img :src="require(`@/assets/images/watch.svg`)" alt="" />
        </router-link>
        <div>
          <h4>Abc sks</h4>
          <p>by Yuism</p>
        </div>
      </div>
    </div>
    <footer>
      <router-link :to="{ name: 'HomePage' }">back home</router-link>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ExploreGifts",
  components: {}
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/pages/_exploregifts";
</style>
